import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CommonUtils } from '../utils/common-utils';
import { DateUtils } from '../utils/date-utils';

export class DateValidator {
  static getMmSlashDdSlashYyStringValidator(): ValidatorFn {
    return (formControl: FormControl): ValidationErrors | null => {
      if (CommonUtils.isNullOrUndefinedOrEmptyString(formControl.value)) {
        return null;
      }

      if (!/^\d{2}\/\d{2}\/\d{2}$/.test(formControl.value)) {
        return { invalidDate: { format: 'MM/DD/YY' } };
      }

      const segments = formControl.value.split('/');
      const month = parseInt(segments[0], 10);
      const day = parseInt(segments[1], 10);
      const year = DateUtils.convertTwoDigitYearToFourDigitYear(parseInt(segments[2], 10)); // Handle 2-digit year logic

      if (month < 1 || month > 12) {
        return { invalidMonth: { min: 1, max: 12 } };
      }
      if (year < 1931 || year > 2030) {
        return { invalidYear: { min: 1931, max: 2030 } };
      }

      const isLeapYear = year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
      const daysInMonth = [31, isLeapYear ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (day > daysInMonth[month - 1]) {
        return { invalidDay: { min: 1, max: daysInMonth[month - 1] } };
      }

      return null;
    };
  }

  static lessThan(formControl1: FormControl, formControl2: FormControl): ValidatorFn {
    return (): ValidationErrors | null => {
      const date1 = DateValidator.stripTime(new Date(formControl1.value));
      const date2 = DateValidator.stripTime(new Date(formControl2.value));
      if (date1 < date2) {
        return null;
      }
      return { dateLessThan: { value: DateUtils.convertDateStringToMmSlashDdSlashYyOrNull(formControl2.value) } };
    };
  }

  static lessThanOrEqualTo(formControl1: FormControl, formControl2: FormControl): ValidatorFn {
    return (): ValidationErrors | null => {
      const date1 = DateValidator.stripTime(new Date(formControl1.value));
      const date2 = DateValidator.stripTime(new Date(formControl2.value));
      if (date1 <= date2) {
        return null;
      }
      return { dateLessThanOrEqualTo: { value: DateUtils.convertDateStringToMmSlashDdSlashYyOrNull(formControl2.value) } };
    };
  }

  static greaterThan(formControl1: FormControl, formControl2: FormControl): ValidatorFn {
    return (): ValidationErrors | null => {
      const date1 = DateValidator.stripTime(new Date(formControl1.value));
      const date2 = DateValidator.stripTime(new Date(formControl2.value));
      if (date1 > date2) {
        return null;
      }
      return { dateGreaterThan: { value: DateUtils.convertDateStringToMmSlashDdSlashYyOrNull(formControl2.value) } };
    };
  }

  static greaterThanOrEqualTo(formControl1: FormControl, formControl2: FormControl): ValidatorFn {
    return (): ValidationErrors | null => {
      const date1 = DateValidator.stripTime(new Date(formControl1.value));
      const date2 = DateValidator.stripTime(new Date(formControl2.value));
      if (date1 >= date2) {
        return null;
      }
      return { dateGreaterThanOrEqualTo: { value: DateUtils.convertDateStringToMmSlashDdSlashYyOrNull(formControl2.value) } };
    };
  }

  static validatorNameValidatorFunctionMap: Map<string, (...args: unknown[]) => ValidatorFn> = new Map();

  static {
    this.populateValidatorNameValidatorFunctionMap();
  }

  private static populateValidatorNameValidatorFunctionMap(): void {
    DateValidator.validatorNameValidatorFunctionMap.set('lessThan', DateValidator.lessThan);
    DateValidator.validatorNameValidatorFunctionMap.set('lessThanOrEqualTo', DateValidator.lessThanOrEqualTo);
    DateValidator.validatorNameValidatorFunctionMap.set('greaterThan', DateValidator.greaterThan);
    DateValidator.validatorNameValidatorFunctionMap.set('greaterThanOrEqualTo', DateValidator.greaterThanOrEqualTo);
  }

  private static stripTime(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }
}
