import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { first, withLatestFrom } from 'rxjs';
import { AppState } from '../../../../../store/state/app.state';
import { AuthSelectors } from '../../../../auth/store/selectors';
import { TechnicalException } from '../../../../shared/data-model/models/technical-exception.model';
import { CollectionUtils } from '../../../../shared/utils/collection-utils';
import { CommonUtils } from '../../../../shared/utils/common-utils';
import { DateUtils } from '../../../../shared/utils/date-utils';
import { OssOptional } from '../../../../shared/utils/oss-optional';
import { StringUtils } from '../../../../shared/utils/string-utils';
import { FormConstants } from '../../../constants/form.constants';
import { FormItemType } from '../../../data-model/enums/form-item-type.enum';
import { NewCrashReportFormValue } from '../../../data-model/models/new-crash-report-form-value.model';
import { OssActivityRecord } from '../../../data-model/models/oss-activity-record.model';
import { PlateToVinResponse } from '../../../data-model/models/plate-to-vin-response.model';
import { SchemaGroupMetaData } from '../../../data-model/models/schema-group-meta-data.model';
import { SubGroupMetaData } from '../../../data-model/models/sub-group-meta-data.model';
import { CrashReportActions } from '../../../store/actions';
import { ServiceLocationSelectors } from '../../../store/selectors';
import { CrashReportDetailFormKeyService } from '../crash-report-detail-form-key/crash-report-detail-form-key.service';
import { CrashReportDetailFormValueChangesService } from '../crash-report-detail-form-value-changes/crash-report-detail-form-value-changes.service';
import { CrashReportDetailMapService } from '../crash-report-detail-map.service';

type ValueUpdateFieldPath = [string, number, string, number, string];

@Injectable({ providedIn: 'root' })
export class CrashReportDetailValueUpdateService {
  private readonly SERVICE_LOCATION_ID_FIELD_NAME_PATH_MAP_DICT = new Map<number, Map<string, ValueUpdateFieldPath>>();

  constructor(
    private readonly store: Store<AppState>,
    private readonly keyService: CrashReportDetailFormKeyService,
    private readonly mapService: CrashReportDetailMapService,
    private readonly valueChangesService: CrashReportDetailFormValueChangesService
  ) {
    this.SERVICE_LOCATION_ID_FIELD_NAME_PATH_MAP_DICT = new Map<number, Map<string, ValueUpdateFieldPath>>();
    this.initializeServiceLocationIdFieldNamePathMap();
  }

  updateFormOnVinData(
    vinData: PlateToVinResponse,
    schemaGroupMetaData: SchemaGroupMetaData,
    subGroupMetaData: SubGroupMetaData,
    dataIsCorrect: boolean,
    dataIsPartiallyCorrect: boolean,
    dataIsIncorrect: boolean,
    callback: () => void
  ): void {
    this.store
      .select(ServiceLocationSelectors.selectServiceLocation)
      .pipe(first())
      .subscribe(serviceLocation => {
        const serviceLocationId = serviceLocation.id;
        const fieldNamePathMap = this.SERVICE_LOCATION_ID_FIELD_NAME_PATH_MAP_DICT.get(serviceLocationId);
        const argsList: [string, number, string, number, string, unknown, boolean, boolean][] = [];

        const vin = OssOptional.ofNullable(vinData)
          .map(vinData => vinData.vin.vin)
          .orElse(null);
        const year = OssOptional.ofNullable(vinData)
          .map(vinData => vinData.vin.year)
          .orElse(null);
        const make = OssOptional.ofNullable(vinData)
          .map(vinData => vinData.vin.make)
          .orElse(null);
        const model = OssOptional.ofNullable(vinData)
          .map(vinData => vinData.vin.model)
          .orElse(null);

        OssOptional.ofNullable(fieldNamePathMap.get('VIN')).ifPresent(path =>
          argsList.push([path[0], schemaGroupMetaData.index, path[2], subGroupMetaData.index, path[4], vin, false, false])
        );
        OssOptional.ofNullable(fieldNamePathMap.get('VEH_YEAR')).ifPresent(path =>
          argsList.push([path[0], schemaGroupMetaData.index, path[2], subGroupMetaData.index, path[4], year, false, false])
        );
        OssOptional.ofNullable(fieldNamePathMap.get('VEH_MAKE')).ifPresent(path =>
          argsList.push([path[0], schemaGroupMetaData.index, path[2], subGroupMetaData.index, path[4], make, false, false])
        );
        OssOptional.ofNullable(fieldNamePathMap.get('VEH_MODEL')).ifPresent(path =>
          argsList.push([path[0], schemaGroupMetaData.index, path[2], subGroupMetaData.index, path[4], model, false, false])
        );

        argsList.forEach(args => {
          if (dataIsCorrect || dataIsPartiallyCorrect) {
            this.updateValue(args[0], args[1], args[2], args[3], args[4], args[5], args[6], args[7]);
          }
          if (dataIsCorrect) {
            this.hideOrShowDownstreamFormItemsAndUpdateParentPageSectionAndSubgroup(args, true, callback);
          }
          if (dataIsPartiallyCorrect || dataIsIncorrect) {
            this.hideOrShowDownstreamFormItemsAndUpdateParentPageSectionAndSubgroup(args, false, callback);
          }
        });
      });
  }

  updateFormOnApprove(): void {
    this.store
      .select(ServiceLocationSelectors.selectServiceLocationId)
      .pipe(first(), withLatestFrom(this.store.select(AuthSelectors.selectUser)))
      .subscribe(([serviceLocationId, user]) => {
        if (CommonUtils.isNullOrUndefined(user)) {
          throw new TechnicalException('CrashReportDetailValueUpdateService::updateFormOnApprove >> user is null or undefined');
        }

        const fieldNamePathMap = this.SERVICE_LOCATION_ID_FIELD_NAME_PATH_MAP_DICT.get(serviceLocationId);
        if (CommonUtils.isNullOrUndefined(fieldNamePathMap)) {
          throw new TechnicalException('CrashReportDetailValueUpdateService::updateFormOnApprove >> fieldNamePathMap is null or undefined');
        }

        const argsList: [string, number, string, number, string, unknown, boolean, boolean][] = [];

        const titleCasePipe = new TitleCasePipe();
        const reviewerFirstName = OssOptional.ofNullable(user.first_name)
          .map(firstName => titleCasePipe.transform(firstName))
          .orElse('ReviewerFirstName');
        const reviewerLastName = OssOptional.ofNullable(user.last_name)
          .map(lastName => titleCasePipe.transform(lastName))
          .orElse('ReviewerLastName');
        const reviewerRank = 'NOPD Officer';

        OssOptional.ofNullable(fieldNamePathMap.get('REV_OFF_NAME_FIRST')).ifPresent(path =>
          argsList.push([path[0], path[1], path[2], path[3], path[4], reviewerFirstName, false, false])
        );
        OssOptional.ofNullable(fieldNamePathMap.get('REV_OFF_NAME_LAST')).ifPresent(path =>
          argsList.push([path[0], path[1], path[2], path[3], path[4], reviewerLastName, false, false])
        );
        OssOptional.ofNullable(fieldNamePathMap.get('REV_OFF_RANK')).ifPresent(path =>
          argsList.push([path[0], path[1], path[2], path[3], path[4], reviewerRank, false, false])
        );

        argsList.forEach(args => this.updateValue(args[0], args[1], args[2], args[3], args[4], args[5], args[6], args[7]));
      });
  }

  updateFormOnArrival(newCrashReportFormValue: NewCrashReportFormValue): void {
    this.store
      .select(ServiceLocationSelectors.selectServiceLocation)
      .pipe(withLatestFrom(this.store.select(AuthSelectors.selectUser)), first())
      .subscribe(([serviceLocation, user]) => {
        /**
         * notes:
         *    crash date and crash time need to be editable if the crash occured the day before
         *    date and time police notified needs to be linked to dispatch time and date, is this dispatched from the on arrival workflow?
         *    date and time police arrived needs to be linked to the on scene button. i don't know what this is. is this the arrived at button?
         *    date and time investigation complete should be automatic on submit, how do i submit?
         */

        const serviceLocationId = serviceLocation.id;
        const fieldNamePathMap = this.SERVICE_LOCATION_ID_FIELD_NAME_PATH_MAP_DICT.get(serviceLocationId);

        const argsList: [string, number, string, number, string, unknown, boolean, boolean][] = [];
        // set report num 2
        // report num 2 will be defined if the crash report was created while the app was offline
        if (CommonUtils.isDefined(newCrashReportFormValue.reportNum2)) {
          OssOptional.ofNullable(fieldNamePathMap.get('REPORT_NUM_2')).ifPresent(path =>
            this.updateValue(path[0], path[1], path[2], path[3], path[4], newCrashReportFormValue.reportNum2, false, false)
          );
        }

        // set location fields
        if (
          CommonUtils.isDefinedAndNonEmptyString(newCrashReportFormValue.latitude) &&
          CommonUtils.isDefinedAndNonEmptyString(newCrashReportFormValue.longitude)
        ) {
          OssOptional.ofNullable(fieldNamePathMap.get('LAT')).ifPresent(path =>
            this.updateValue(path[0], path[1], path[2], path[3], path[4], newCrashReportFormValue.latitude, false, false)
          );
          OssOptional.ofNullable(fieldNamePathMap.get('LONG')).ifPresent(path =>
            this.updateValue(path[0], path[1], path[2], path[3], path[4], newCrashReportFormValue.longitude, false, false)
          );
          OssOptional.ofNullable(fieldNamePathMap.get('CRASH_COORDINATES')).ifPresent(path => {
            const location = `${newCrashReportFormValue.latitude},${newCrashReportFormValue.longitude}`;
            this.updateValue(path[0], path[1], path[2], path[3], path[4], location, false, false);
          });
        }

        // set investigating officer fields
        const titleCasePipe = new TitleCasePipe();
        const userFirstName = OssOptional.ofNullable(user.first_name)
          .map(firstName => titleCasePipe.transform(firstName))
          .orElse('UserFirstName');
        const userLastName = OssOptional.ofNullable(user.last_name)
          .map(lastName => titleCasePipe.transform(lastName))
          .orElse('UserLastName');
        const userRank = 'OSS';
        OssOptional.ofNullable(fieldNamePathMap.get('INVEST_OFFICER')).ifPresent(path =>
          argsList.push([path[0], path[1], path[2], path[3], path[4], `${userLastName}, ${userFirstName}`, false, false])
        );
        OssOptional.ofNullable(fieldNamePathMap.get('INV_OFF_NAME_FIRST')).ifPresent(path =>
          argsList.push([path[0], path[1], path[2], path[3], path[4], userFirstName, false, false])
        );
        OssOptional.ofNullable(fieldNamePathMap.get('INV_OFF_NAME_LAST')).ifPresent(path =>
          argsList.push([path[0], path[1], path[2], path[3], path[4], userLastName, false, false])
        );
        OssOptional.ofNullable(fieldNamePathMap.get('INV_OFF_RANK')).ifPresent(path =>
          argsList.push([path[0], path[1], path[2], path[3], path[4], userRank, false, false])
        );

        // set date fields
        const crashDate = DateUtils.convertDateObjectToUtcStringAtSixAm(newCrashReportFormValue.crashDate);
        OssOptional.ofNullable(fieldNamePathMap.get('CRASH_DATE')).ifPresent(path =>
          argsList.push([path[0], path[1], path[2], path[3], path[4], crashDate, true, false])
        );
        OssOptional.ofNullable(fieldNamePathMap.get('DATE_POLICE_NOTE')).ifPresent(path =>
          argsList.push([path[0], path[1], path[2], path[3], path[4], crashDate, true, false])
        );
        OssOptional.ofNullable(fieldNamePathMap.get('DATE_POLICE_ARR')).ifPresent(path =>
          argsList.push([path[0], path[1], path[2], path[3], path[4], crashDate, true, false])
        );
        OssOptional.ofNullable(fieldNamePathMap.get('DATE_LANES_OPEN')).ifPresent(path =>
          argsList.push([path[0], path[1], path[2], path[3], path[4], crashDate, true, false])
        );

        argsList.forEach(args => this.updateValue(args[0], args[1], args[2], args[3], args[4], args[5], args[6], args[7]));
      });
  }

  updateFormOnSubmit(updateDateAndTimeInvestigationComplete: boolean = true, isRedirectFromActivity: boolean, activityRecord: OssActivityRecord): void {
    this.store
      .select(ServiceLocationSelectors.selectServiceLocation)
      .pipe(first())
      .subscribe(serviceLocation => {
        const argsList: [string, number, string, number, string, unknown, boolean, boolean][] = [];
        const fieldNamePathMap = this.SERVICE_LOCATION_ID_FIELD_NAME_PATH_MAP_DICT.get(serviceLocation.id);

        let dateInvestigationComplete = DateUtils.convertDateObjectToUtcStringAtSixAm(new Date());
        let timeInvestigationComplete = DateUtils.convertDateObjectTo24HourHhColonMmString(new Date());
        const dateChanged = DateUtils.convertDateObjectToUtcStringAtSixAm(new Date());

        if (isRedirectFromActivity) {
          dateInvestigationComplete = OssOptional.ofNullable(activityRecord)
            .map(record => record.time_closed)
            .map(timeClosed => DateUtils.convertDateObjectToUtcStringAtSixAm(timeClosed))
            .orElse(DateUtils.convertDateObjectToUtcStringAtSixAm(new Date()));

          timeInvestigationComplete = OssOptional.ofNullable(activityRecord)
            .map(record => record.time_closed)
            .map(timeClosed => DateUtils.convertDateObjectTo24HourHhColonMmString(timeClosed))
            .orElse(DateUtils.convertDateObjectTo24HourHhColonMmString(new Date()));
        }

        if (updateDateAndTimeInvestigationComplete) {
          // set the date and time investigation complete to the current date and time
          OssOptional.ofNullable(fieldNamePathMap.get('DATE_INVEST_COMP')).ifPresent(path =>
            argsList.push([path[0], path[1], path[2], path[3], path[4], dateInvestigationComplete, true, false])
          );
          OssOptional.ofNullable(fieldNamePathMap.get('TIME_INVEST_COMP')).ifPresent(path =>
            argsList.push([path[0], path[1], path[2], path[3], path[4], timeInvestigationComplete, false, false])
          );
          OssOptional.ofNullable(fieldNamePathMap.get('DATE_CHANGED')).ifPresent(path =>
            argsList.push([path[0], path[1], path[2], path[3], path[4], dateChanged, true, false])
          );
        }

        argsList.forEach(args => this.updateValue(args[0], args[1], args[2], args[3], args[4], args[5], args[6], args[7]));
      });
  }

  private updateValue(
    schemaGroupDomId: string,
    schemaGroupIndex: number,
    subGroupDomId: string,
    subGroupIndex: number,
    formItemFieldName: string,
    value: unknown,
    isDate: boolean,
    shouldFindClosestMatch: boolean
  ): void {
    const { ECRASH_REF, ECRASH_REF_MULTI, BUTTON_GROUP, BUTTON_GROUP_MULTI } = FormItemType;
    const { META_DATA, TYPE, VALUE, ITEMS, HIDDEN_ITEMS } = FormConstants;

    const compositeKey = this.keyService.getFormItemCompositeKey(schemaGroupDomId, schemaGroupIndex, subGroupDomId, subGroupIndex, formItemFieldName);
    const formItemFormGroup = OssOptional.ofNullable(this.mapService.formItemCompositeKeyFormGroupMap.get(compositeKey)).orElseThrow(
      () => new TechnicalException(`form item with key ${formItemFieldName} not found`)
    );

    let formattedValue = value;
    if (isDate) {
      // oss input expects string in form mm/dd/yy
      formattedValue = DateUtils.convertDateStringToMmSlashDdSlashYyOrNull(value.toString());
    }

    if ([ECRASH_REF, ECRASH_REF_MULTI, BUTTON_GROUP, BUTTON_GROUP_MULTI].includes(formItemFormGroup.value[META_DATA][TYPE]) && shouldFindClosestMatch) {
      if (CommonUtils.isDefinedAndNonEmptyString(formattedValue)) {
        const closestMatch = CollectionUtils.findClosestMatch(formattedValue.toString(), [
          ...formItemFormGroup.value[META_DATA][ITEMS],
          ...OssOptional.ofNullable(formItemFormGroup.value[META_DATA][HIDDEN_ITEMS]).orElse([]),
        ]);
        // oss auto complete expects list of selected values or list of selected value
        formattedValue = [closestMatch.value];
      } else {
        formattedValue = [null];
      }
    }

    formItemFormGroup.controls[VALUE].setValue(formattedValue, { emitEvent: true });

    let dbValue = value;
    if (isDate) {
      // send db date object
      dbValue = value;
    }
    if ([ECRASH_REF, ECRASH_REF_MULTI, BUTTON_GROUP, BUTTON_GROUP_MULTI].includes(formItemFormGroup.value[META_DATA][TYPE])) {
      // send db the formatted value, probably have to send a list of values or list of value type is ecrah ref multi
      dbValue = formattedValue;
    }
    // this is ugly but necessary: the api expects vin to be an empty string instead of null
    // if another metro area has a different form item field name we can add a map of service location id to form item field name
    if (formItemFieldName.toLowerCase() === 'vin' && dbValue === null) {
      dbValue = StringUtils.EMPTY;
    }
    this.store.dispatch(
      CrashReportActions.updateCrashReportDetail({
        metaData: formItemFormGroup.value[META_DATA],
        value: dbValue,
        isValid: formItemFormGroup.valid,
        isSetInBackground: true,
      })
    );
  }

  private initializeServiceLocationIdFieldNamePathMap(): void {
    this.configureBaltimoreFieldNameMap();
    this.configureNewOrleansFieldNameMap();
  }

  private hideOrShowDownstreamFormItemsAndUpdateParentPageSectionAndSubgroup(
    args: [string, number, string, number, string, unknown, boolean, boolean],
    shouldHide: boolean,
    callback: () => void
  ): void {
    const formItemCompositeKey = this.keyService.getFormItemCompositeKey(args[0], args[1], args[2], args[3], args[4]);
    OssOptional.ofNullable(this.mapService.formItemCompositeKeyFormGroupMap.get(formItemCompositeKey)).ifPresentOrElse(
      formItemFormGroup => {
        const metaDataFormGroup = formItemFormGroup.controls[FormConstants.META_DATA];
        metaDataFormGroup.patchValue({ ...metaDataFormGroup.value, [FormConstants.SHOULD_HIDE]: shouldHide });

        this.valueChangesService.patchImpactedPageFormGroup(formItemFormGroup);
        this.valueChangesService.patchImpactedSectionFormGroup(formItemFormGroup);
        this.valueChangesService.patchImpactedSubGroupFormGroup(formItemFormGroup);

        callback();
      },
      () => {
        throw new TechnicalException(`form item with key ${formItemCompositeKey} not found`);
      }
    );
  }

  private configureBaltimoreFieldNameMap(): void {
    const fieldNameMap = new Map<string, ValueUpdateFieldPath>();

    // on arrival fields
    fieldNameMap.set('REPORT_NUM_2', ['reportdetails', 0, 'reportdetails', 0, 'REPORT_NUM_2']);
    fieldNameMap.set('LAT', ['reportdetails', 0, 'reportdetails', 0, 'LAT']);
    fieldNameMap.set('LONG', ['reportdetails', 0, 'reportdetails', 0, 'LONG']);
    fieldNameMap.set('DATE_POLICE_NOTE', ['reportdetails', 0, 'reportdetails', 0, 'DATE_POLICE_NOTE']);
    fieldNameMap.set('TIME_POLICE_NOTE', ['reportdetails', 0, 'reportdetails', 0, 'TIME_POLICE_NOTE']);
    fieldNameMap.set('DATE_POLICE_ARR', ['reportdetails', 0, 'reportdetails', 0, 'DATE_POLICE_ARR']);
    fieldNameMap.set('TIME_POLICE_ARR', ['reportdetails', 0, 'reportdetails', 0, 'TIME_POLICE_ARR']);
    fieldNameMap.set('INVEST_OFFICER', ['reportdetails', 0, 'reportdetails', 0, 'INVEST_OFFICER']);
    fieldNameMap.set('CRASH_DATE', ['crashdetails', 0, 'crashdetails', 0, 'CRASH_DATE']);
    fieldNameMap.set('DATE_POLICE_NOTE', ['crashdetails', 0, 'crashdetails', 0, 'DATE_POLICE_NOTE']);
    fieldNameMap.set('DATE_POLICE_ARR', ['crashdetails', 0, 'crashdetails', 0, 'DATE_POLICE_ARR']);
    fieldNameMap.set('DATE_LANES_OPEN', ['crashdetails', 0, 'crashdetails', 0, 'DATE_LANES_OPEN']);
    fieldNameMap.set('DATE_PARTIES_DISCHARGED', ['reportdetails', 0, 'reportdetails', 0, 'DATE_PARTIES_DISCHARGED']);
    fieldNameMap.set('TIME_PARTIES_DISCHARGED', ['reportdetails', 0, 'reportdetails', 0, 'TIME_PARTIES_DISCHARGED']);

    // on submit fields
    fieldNameMap.set('DATE_INVEST_COMP', ['reportdetails', 0, 'reportdetails', 0, 'DATE_INVEST_COMP']);
    fieldNameMap.set('TIME_INVEST_COMP', ['reportdetails', 0, 'reportdetails', 0, 'TIME_INVEST_COMP']);

    // license plate fields
    fieldNameMap.set('VIN', ['vehic', 0, 'vehic', 0, 'VIN']);
    fieldNameMap.set('VEH_YEAR', ['vehic', 0, 'vehic', 0, 'VEH_YEAR']);
    fieldNameMap.set('VEH_MAKE', ['vehic', 0, 'vehic', 0, 'VEH_MAKE']);
    fieldNameMap.set('VEH_MODEL', ['vehic', 0, 'vehic', 0, 'VEH_MODEL']);

    this.SERVICE_LOCATION_ID_FIELD_NAME_PATH_MAP_DICT.set(2, fieldNameMap);
  }

  private configureNewOrleansFieldNameMap(): void {
    // initialize the field name map
    const fieldNameMap = new Map<string, ValueUpdateFieldPath>();

    // on arrival fields
    fieldNameMap.set('REPORT_NUM_2', ['hidden', 0, 'hidden', 0, 'REPORT_NUM_2']);
    fieldNameMap.set('LAT', ['hidden', 0, 'hidden', 0, 'LAT']);
    fieldNameMap.set('LONG', ['hidden', 0, 'hidden', 0, 'LONG']);
    fieldNameMap.set('CRASH_COORDINATES', ['crash', 0, 'location', 0, 'CRASH_COORDINATES']);
    fieldNameMap.set('DATE_POLICE_NOTE', ['crash', 0, 'crash', 0, 'DATE_POLICE_NOTE']);
    fieldNameMap.set('TIME_POLICE_NOTE', ['crash', 0, 'crash', 0, 'TIME_POLICE_NOTE']);
    fieldNameMap.set('DATE_POLICE_ARR', ['crash', 0, 'crash', 0, 'DATE_POLICE_ARR']);
    fieldNameMap.set('TIME_POLICE_ARR', ['crash', 0, 'crash', 0, 'TIME_POLICE_ARR']);
    fieldNameMap.set('DATE_LANES_OPEN', ['crash', 0, 'crash', 0, 'DATE_LANES_OPEN']);
    fieldNameMap.set('TIME_LANES_OPEN', ['crash', 0, 'crash', 0, 'TIME_LANES_OPEN']);
    fieldNameMap.set('INVEST_OFFICER', ['hidden', 0, 'hidden', 0, 'INVEST_OFFICER']);
    fieldNameMap.set('INV_OFF_NAME_FIRST', ['hidden', 0, 'hidden', 0, 'INV_OFF_NAME_FIRST']);
    fieldNameMap.set('INV_OFF_NAME_LAST', ['hidden', 0, 'hidden', 0, 'INV_OFF_NAME_LAST']);
    fieldNameMap.set('INV_OFF_RANK', ['hidden', 0, 'hidden', 0, 'INV_OFF_RANK']);
    fieldNameMap.set('CRASH_DATE', ['crash', 0, 'crash', 0, 'CRASH_DATE']);

    // on submit fields
    fieldNameMap.set('DATE_INVEST_COMP', ['hidden', 0, 'hidden', 0, 'DATE_INVEST_COMP']);
    fieldNameMap.set('TIME_INVEST_COMP', ['hidden', 0, 'hidden', 0, 'TIME_INVEST_COMP']);
    fieldNameMap.set('DATE_CHANGED', ['hidden', 0, 'hidden', 0, 'DATE_CHANGED']);

    // on approve fields -- these paths come from the review form schema in the form service, not the standard form schema
    fieldNameMap.set('REV_OFF_NAME_FIRST', ['schemaKey', 0, 'subGroupKey', 0, 'REV_OFF_NAME_FIRST']);
    fieldNameMap.set('REV_OFF_NAME_LAST', ['schemaKey', 0, 'subGroupKey', 0, 'REV_OFF_NAME_LAST']);
    fieldNameMap.set('REV_OFF_RANK', ['schemaKey', 0, 'subGroupKey', 0, 'REV_OFF_RANK']);

    // license plate fields
    fieldNameMap.set('VIN', ['vehic', 0, 'vehic', 0, 'VIN']);
    fieldNameMap.set('VEH_YEAR', ['vehic', 0, 'vehic', 0, 'VEH_YEAR']);
    fieldNameMap.set('VEH_MAKE', ['vehic', 0, 'vehic', 0, 'VEH_MAKE']);
    fieldNameMap.set('VEH_MODEL', ['vehic', 0, 'vehic', 0, 'VEH_MODEL']);

    this.SERVICE_LOCATION_ID_FIELD_NAME_PATH_MAP_DICT.set(1, fieldNameMap);
  }
}
