import moment, { Moment } from 'moment';
import { TechnicalException } from '../data-model/models/technical-exception.model';
import { CommonUtils } from './common-utils';
import { StringUtils } from './string-utils';

export class DateUtils {
  static convertDateObjectToUtcStringAtSixAm(date: Date): string {
    const clonedDate = new Date(date.getTime());
    clonedDate.setUTCFullYear(clonedDate.getFullYear(), clonedDate.getMonth(), clonedDate.getDate());
    clonedDate.setUTCHours(6, 0, 0, 0);

    const year = clonedDate.getUTCFullYear();
    const month = String(clonedDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(clonedDate.getUTCDate()).padStart(2, '0');
    const hours = String(clonedDate.getUTCHours()).padStart(2, '0');
    const minutes = String(clonedDate.getUTCMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  static convertDateObjectTo24HourHhColonMmString(date: Date): string {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  static isMmSlashDdSlashYyString(value: string): boolean {
    if (CommonUtils.isNullOrUndefinedOrEmptyString(value)) {
      return false;
    }
    return /^\d{2}\/\d{2}\/\d{2}$/.test(value); // Adjust to MM/DD/YY
  }

  static convertMmSlashDdSlashYyStringToDateObject(value: string): Date {
    const year = DateUtils.convertTwoDigitYearToFourDigitYear(parseInt(value.slice(6), 10)); // Adjust year interpretation
    const month = parseInt(value.slice(0, 2), 10) - 1;
    const day = parseInt(value.slice(3, 5), 10);
    return new Date(year, month, day);
  }

  static convertTwoDigitYearToFourDigitYear(year: number): number {
    return year <= 30 ? 2000 + year : 1900 + year; // Handle two-digit year logic
  }

  static isHhMmString(value: string): boolean {
    if (CommonUtils.isNullOrUndefinedOrEmptyString(value)) {
      return false;
    }
    return /^\d{4}$/.test(value);
  }

  static convertHhMmStringToCurrentDateObjectAtHhMm(value: string): Date {
    const hours = parseInt(value.slice(0, 2), 10);
    const minutes = parseInt(value.slice(2), 10);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return date;
  }

  static covertMomentToIso8601DateString(value: Moment): string {
    const utcMoment = value.utc();
    return utcMoment.startOf('day').hours(6).format('YYYY-MM-DDTHH:mm');
  }

  static convertDateStringToMmSlashDdSlashYyOrNull(dateString: string): string | null {
    if (CommonUtils.isNullOrUndefined(dateString)) {
      return null;
    }

    const date = new Date(dateString);
    const isValidDate = !isNaN(date.getTime());
    if (!isValidDate) {
      return null;
    }

    const utcDateString = date.toISOString();
    const [year, month, day] = utcDateString.substring(0, 10).split('-');
    const paddedMonth = month.padStart(2, '0');
    const paddedDay = day.padStart(2, '0');
    const paddedYear = parseInt(year.slice(-2), 10).toString().padStart(2, '0');
    return `${paddedMonth}/${paddedDay}/${paddedYear}`;
  }

  static convertDateObjectToHhMmOrNull(date: Date): string | null {
    if (CommonUtils.isNullOrUndefined(date)) {
      return null;
    }

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}${minutes}`;
  }

  static convertHhMmToHhColonMm(value: string): string {
    if (CommonUtils.isNullOrUndefined(value) || value === StringUtils.EMPTY) {
      return null;
    }
    if (!/^\d{4}$/.test(value)) {
      throw new TechnicalException('Invalid time format:' + value);
    }
    return `${value.slice(0, 2)}:${value.slice(2)}`;
  }

  static convertHhColonMmToHhMmOrNull(value: string): string {
    if (CommonUtils.isNullOrUndefined(value) || value === StringUtils.EMPTY) {
      return null;
    }
    if (!/^\d{2}:\d{2}$/.test(value)) {
      throw new TechnicalException('Invalid time format:' + value);
    }
    return value.replace(':', '');
  }

  static isIso8601DateString(value: string): boolean {
    return moment(value, moment.ISO_8601, true).isValid();
  }

  static isHhColonMmString(value: string): boolean {
    return /^\d{2}:\d{2}$/.test(value);
  }

  static covertDateObjectAndHhMmTimeStringToIso8601DateString(date: Date, time: string): string {
    if (CommonUtils.isNullOrUndefined(date) || CommonUtils.isNullOrUndefined(time)) {
      return null;
    }
    date.setHours(parseInt(time.slice(0, 2), 10));
    date.setMinutes(parseInt(time.slice(2), 10));
    return moment(date).toISOString();
  }

  static convertDateObjectAndHhMmTimeStringToDateTimeStringWithoutTimeZoneAdjustment(date: Date, time: string): string {
    if (CommonUtils.isNullOrUndefinedOrEmptyString(date) || CommonUtils.isNullOrUndefinedOrEmptyString(time)) {
      return null;
    }
    date.setHours(parseInt(time.slice(0, 2), 10));
    date.setMinutes(parseInt(time.slice(2), 10));
    return moment(date).format('YYYY-MM-DDTHH:mm:ss');
  }

  static convertDateTimeStringWithoutTimeZoneAdjustmentToMmSlashDdSlashYyString(dateTimeString: string): string {
    if (CommonUtils.isNullOrUndefinedOrEmptyString(dateTimeString)) {
      return null;
    }
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year.toString().slice(-2)}`;
  }

  static convertMmSlashDdSlashYyStringAndHhMmStringToIso8601DateString(date: string, time: string): string {
    if (CommonUtils.isNullOrUndefinedOrEmptyString(date) || CommonUtils.isNullOrUndefinedOrEmptyString(time)) {
      return null;
    }
    const dateObject = DateUtils.convertMmSlashDdSlashYyStringToDateObject(date);
    return DateUtils.covertDateObjectAndHhMmTimeStringToIso8601DateString(dateObject, time);
  }

  static mapP1DateTimeToIsoString(dateStr: string): Date {
    if (!dateStr || typeof dateStr !== 'string' || dateStr.trim() === '') {
      return null;
    }

    // Step 1a: Check if string is in a standard ISO format, e.g. "2025-01-16T11:32:26Z"
    // ADDED FOR ISO CHECK
    const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z$/;
    if (isoRegex.test(dateStr.trim())) {
      const isoDate = new Date(dateStr);
      if (!isNaN(isoDate.getTime())) {
        return isoDate;
      }
    }

    // --- The rest is your original logic for strings like "Oct 1 2024 2:28PM" ---

    // Step 2: Extract the first date before the first comma
    const firstDateStr = dateStr.split(',')[0].trim();

    // Step 3: Normalize the string (remove extra spaces)
    const normalizedStr = firstDateStr.replace(/\s+/g, ' ').trim();

    // Step 4: Split the string into components
    // Expected format after normalization: ["Oct", "1", "2024", "2:28PM"]
    const parts = normalizedStr.split(' ');
    if (parts.length !== 4) {
      console.warn(`unexpected date format: ${dateStr}`);
      return null;
    }

    const [monthStr, dayStr, yearStr, timeStr] = parts;

    // Step 5: Map month abbreviations to numbers (0-based for JavaScript Date)
    const monthMap = {
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    };

    const month = monthMap[monthStr];
    if (month === undefined) {
      console.warn(`unknown month abbreviation: ${monthStr}`);
      return null;
    }

    // Step 6: Parse and validate day and year
    const day = parseInt(dayStr, 10);
    const year = parseInt(yearStr, 10);

    if (isNaN(day) || day < 1 || day > 31) {
      console.warn(`invalid day value: ${dayStr}`);
      return null;
    }

    if (isNaN(year) || year < 1000 || year > 9999) {
      console.warn(`invalid year value: ${yearStr}`);
      return null;
    }

    // Step 7: Parse and convert time to 24-hour format
    // Expected time format: "2:28PM" or "12:30AM"
    const timeMatch = timeStr.match(/^(\d{1,2}):(\d{2})(AM|PM)$/i);
    if (!timeMatch) {
      console.warn(`unexpected time format: ${timeStr}`);
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, hourStr, minuteStr, period] = timeMatch;
    let hour = parseInt(hourStr, 10);
    const minute = parseInt(minuteStr, 10);

    if (isNaN(hour) || hour < 1 || hour > 12) {
      console.warn(`invalid hour value: ${hourStr}`);
      return null;
    }

    if (isNaN(minute) || minute < 0 || minute > 59) {
      console.warn(`invalid minute value: ${minuteStr}`);
      return null;
    }

    // Convert hour based on AM/PM
    if (/PM/i.test(period) && hour !== 12) {
      hour += 12;
    }
    if (/AM/i.test(period) && hour === 12) {
      hour = 0;
    }

    // Step 8: Create the Date object (assuming local timezone)
    const date = new Date(year, month, day, hour, minute, 0);

    // Step 9: Validate the constructed Date object by comparing components
    if (date.getFullYear() !== year || date.getMonth() !== month || date.getDate() !== day || date.getHours() !== hour || date.getMinutes() !== minute) {
      console.warn(`invalid date components: ${dateStr}`);
      return null;
    }

    return date;
  }

  static extractHHColonMMFromIso8601DateTimeString(dateTimeString: Date): string {
    if (CommonUtils.isNullOrUndefinedOrEmptyString(dateTimeString)) {
      return null;
    }
    const date = new Date(dateTimeString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }
}
