import { createAction, props } from '@ngrx/store';
import { ActivityRecordVerificationStatus } from '../../data-model/enums/activity-record-verification-status.enum';
import { ActivitySummaryFilter } from '../../data-model/models/activity-summary-filter.model';
import { CloseEventRequest } from '../../data-model/models/close-event-request.model';
import { OssActivityRecord } from '../../data-model/models/oss-activity-record.model';

const feature = '[Activity]';

export const loadActivityRecords = createAction(`${feature} Load Activity Records`, props<{ fetchActivityRecords: boolean }>());
export const activityRecordsLoaded = createAction(
  `${feature} Activity Records Loaded`,
  props<{ activityRecords: OssActivityRecord[]; numberOfActivityRecords: number }>()
);

export const setVerificationStatus = createAction(
  `${feature} Set Verification Status`,
  props<{ verificationStatus: ActivityRecordVerificationStatus; fetchActivityRecords: boolean }>()
);
export const setLimit = createAction(`${feature} Set Limit`, props<{ limit: number; fetchActivityRecords?: boolean }>());
export const setOffset = createAction(`${feature} Set Offset`, props<{ offset: number; fetchActivityRecords?: boolean }>());
export const setFilter = createAction(`${feature} Set Filter`, props<{ filter: ActivitySummaryFilter; fetchActivityRecords?: boolean }>());

export const resetStateAndPreserveActivityRecord = createAction(`${feature} Reset State And Preserve Activity Record`);

export const loadUnverifiedActivityRecords = createAction(`${feature} Load Unverified Activity Records`);
export const unverifiedActivityRecordsLoaded = createAction(
  `${feature} Unverified Activity Records Loaded`,
  props<{ numberOfUnverifiedActivityRecords: number }>()
);

export const loadMissingActivityRecords = createAction(`${feature} Load Missing Activity Records`);
export const missingActivityRecordsLoaded = createAction(`${feature} Missing Activity Records Loaded`, props<{ numberOfMissingActivityRecords: number }>());

export const openRevisionModal = createAction(`${feature} Open Revision Modal`, props<{ activityRecord: OssActivityRecord; isInitialVerification: boolean }>());
export const updateItemNumber = createAction(`${feature} Update Item Number`, props<{ activityRecord: OssActivityRecord; itemNumber: string }>());
export const itemNumberUpdated = createAction(`${feature} Item Number Updated`, props<{ itemNumber: string }>());

export const closeItem = createAction(`${feature} Close Item`, props<{ request: CloseEventRequest }>());
export const itemClosed = createAction(`${feature} Item Closed`);

export const setActivityRecord = createAction(`${feature} Set Activity Record`, props<{ activityRecord: OssActivityRecord }>());

export const resetActivityState = createAction(`${feature} Reset Activity State`);
