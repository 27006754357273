export interface ActivitySummaryFilter {
  itemNumber: string;
  agent: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  location: string;
}

export function getNulledOutActivitySummaryFilter(): ActivitySummaryFilter {
  return {
    itemNumber: null,
    agent: null,
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    location: null,
  };
}
