import { createAction, props } from '@ngrx/store';
import { CrashReportDetail } from '../../data-model/models/crash-report-detail.model';
import { CrashReportSummaryFilter } from '../../data-model/models/crash-report-summary-filter.model';
import { CrashReportSummary } from '../../data-model/models/crash-report-summary.model';
import { FormItemMetaData } from '../../data-model/models/form-item-meta-data.model';
import { NewCrashReportFormValue } from '../../data-model/models/new-crash-report-form-value.model';
import { CrashReportSummaryListTab } from '../../data-model/types/crash-report-summary-list-tab.type';

const feature = '[Crash Report]';

// crash report summary
export const setCrashReportSummary = createAction(`${feature} Set Crash Report Summary`, props<{ crashReportSummary: CrashReportSummary }>());
export const setSupervisorCrashReportSummary = createAction(
  `${feature} Set Supervisor Crash Report Summary`,
  props<{ supervisorCrashReportSummary: CrashReportSummary }>()
);

// crash report detail
export const loadCrashReportDetail = createAction(`${feature} Load Crash Report Detail`);
export const crashReportDetailLoaded = createAction(`${feature} Crash Report Detail Loaded`, props<{ crashReportDetail: CrashReportDetail }>());

export const updateCrashReportDetail = createAction(
  `${feature} Update Crash Report Detail`,
  props<{ metaData: FormItemMetaData; value: unknown; isValid: boolean; isSetInBackground: boolean }>()
);
export const crashReportDetailUpdated = createAction(`${feature} Crash Report Detail Updated`);

export const addCrashReportDetail = createAction(`${feature} Add Crash Report Detail`, props<{ newCrashReportFormValue: NewCrashReportFormValue }>());
export const crashReportDetailAdded = createAction(`${feature} Crash Report Detail Added`);

export const updateItemNumber = createAction(`${feature} Update Item Number`, props<{ value: string; metaData: FormItemMetaData }>());
export const itemNumberUpdated = createAction(`${feature} Item Number Updated`, props<{ updateWasSuccessful: boolean }>());

export const deleteCrashReport = createAction(`${feature} Delete Crash Report`, props<{ crashNumber: string }>());
export const crashReportDeleted = createAction(`${feature} Crash Report Deleted`, props<{ deleteWasSuccessful: boolean }>());

// needed to add a new crash report or load an existing crash report
export const setNewCrashReportFormValue = createAction(
  `${feature} Set New Crash Report Form Value`,
  props<{ newCrashReportFormValue: NewCrashReportFormValue }>()
);
export const setIsNewCrashReport = createAction(`${feature} Set Is New Crash Report`, props<{ isNewCrashReport: boolean }>());
export const setLoaderId = createAction(`${feature} Set Loader Id`, props<{ loaderId: string }>());

// review view -> non-review view -> review view
export const navigateToCrashReportDetail = createAction(`${feature} Navigate To Crash Report Detail`, props<{ crashReportNumber: string }>());
export const navigateToNeedsReviewCrashReportDetail = createAction(
  `${feature} Navigate To Needs Review Crash Report Detail`,
  props<{ crashReportNumber: string }>()
);

// crash report summaries
export const crashReportSummariesLoaded = createAction(
  `${feature} Crash Report Summaries Loaded`,
  props<{ crashReportSummaries: CrashReportSummary[]; numberOfCrashReportSummaries: number }>()
);
export const setCurrentLimit = createAction(`${feature} Set Current Limit`, props<{ currentLimit: number; fetchSummaries?: boolean }>());
export const setCurrentOffset = createAction(`${feature} Set Current Offset`, props<{ currentOffset: number; fetchSummaries?: boolean }>());
export const setCurrentTab = createAction(`${feature} Set Current Tab`, props<{ tab: CrashReportSummaryListTab; fetchSummaries?: boolean }>());
export const setCurrentFilter = createAction(`${feature} Set Current Filter`, props<{ currentFilter: CrashReportSummaryFilter; fetchSummaries?: boolean }>());

// numbers to be displayed in the horizontal nav bar
export const loadNeedsReviewCrashReportSummaries = createAction(`${feature} Load Needs Review Crash Report Summaries`);
export const needsReviewCrashReportSummariesLoaded = createAction(
  `${feature} Needs Review Crash Report Summaries Loaded`,
  props<{ numberOfNeedsReviewCrashReportSummaries: number }>()
);

export const loadMyRejectedCrashReportSummaries = createAction(`${feature} Load My Rejected Crash Report Summaries`);
export const myRejectedCrashReportSummariesLoaded = createAction(
  `${feature} My Rejected Crash Report Summaries Loaded`,
  props<{ numberOfMyRejectedCrashReportSummaries: number }>()
);

// reset state
export const resetCrashReportState = createAction(`${feature} Reset Crash Report State`);
